<template>
  <div
    class="uk-flex uk-flex-center uk-flex-middle bg-white"
    uk-height-viewport
    :data-src="backgrounds[Math.floor(Math.random() * backgrounds.length)]"
    uk-img
  >
    <div>
      <div
        class="border border-gray-200 shadow-2xl rounded-2xl uk-width-medium uk-padding"
      >
        <div class="uk-text-center">
          <img :src="api + '/storefronts/' + storefrontId + '/logo'" alt />
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombres"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.first_name"
              placeholder="Nombres"
              name="Nombres"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Apellidos"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              id="last_name"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.last_name"
              placeholder="Apellidos"
              name="Apellidos"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Celular"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              id="mobile_phone"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.mobile_phone"
              placeholder="Celular"
              name="Celular"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Teléfono" v-slot="{ errors }">
            <input
              id="phone"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.phone"
              placeholder="Teléfono"
              name="Teléfono"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Correo"
            rules="required|email|confirmed:email_confirmation"
            v-slot="{ errors }"
          >
            <input
              id="email"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email"
              placeholder="Correo"
              name="Correo"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            vid="email_confirmation"
            name="Confirmación de correo"
            rules="required|email"
            v-slot="{ errors }"
          >
            <input
              id="email_confirmation"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email_confirmation"
              placeholder="Confirmación de correo"
              name="Confirmación de correo"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Contraseña"
            rules="required|length:5|confirmed:password_confirmation"
            v-slot="{ errors }"
          >
            <PasswordInput v-model="model.password" name="Contraseña" />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Confirmación de contraseña"
            rules="required"
            vid="password_confirmation"
            v-slot="{ errors }"
          >
            <PasswordInput
              v-model="model.password_confirmation"
              name="Confirmación de Contraseña"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              @click="register(model)"
              :disabled="invalid"
            >
              Crear cuenta
            </button>
          </div>
        </ValidationObserver>
      </div>
      <div class="uk-text-center uk-margin-top">
        <router-link
          to="/login"
          class="bg-gray-300 px-4 py-2 rounded-full text-gray-900"
        >
          Ya tengo una cuenta
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PasswordInput from "@/views/Utils/PasswordInput";

export default {
  name: "AuthRegister",

  components: {
    PasswordInput,
  },

  data() {
    return {
      model: {
        first_name: "",
        last_name: "",
        point_code: "",
        phone: "",
        mobile_phone: "",
        email: "",
        email_confirmation: "",
        password: "",
        password_confirmation: "",
      },
      backgrounds: [],
      plaintextPassword: false,
    };
  },

  computed: {
    ...mapGetters(["authIsLogingIn", "storefrontStorefront"]),
  },

  methods: {
    ...mapActions(["register"]),
  },
};
</script>
